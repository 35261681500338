import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { isLoggedIn } from "../../util";

import "./styles.scss";

function Menu(props) {
  const [isMenuExpanded, toggleMenu] = useState(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src="/logo.png" alt="logo" />
        </Link>
        <Link to="#" className={"navbar-item spinner "  + (props.isSpinning ? "spinning" : "")}>
          &nbsp;&nbsp;
        </Link>

        {isMenuExpanded ? <>
        <div className="column is-one-quarter is-pulled-right pv-logo">
          PV+&nbsp;
        </div>
        </>
        :
        []}

        <a
          href="#/"
          data-cy="navbar-burger"
          onClick={() => {
            toggleMenu(!isMenuExpanded);
          }}
          role="button"
          className={"navbar-burger " +
            (isMenuExpanded ? "is-active" : "")
          }
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div data-cy="navbar-menu" className={"navbar-menu " + (isMenuExpanded ? "is-active" : "")}>
        <div className="navbar-start">
          {
              isLoggedIn()
              ? <>
            <NavLink to="/search" className="navbar-item">Search</NavLink>
            <NavLink to="/history" className="navbar-item">History</NavLink>
              </>
            :[]
          }
          <NavLink to="/contact" className="navbar-item">Contact Us</NavLink>

        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              {
                isLoggedIn()
                ?
                <NavLink to="/logout" className="button is-primary">
                  <strong>Logout</strong>
                </NavLink>
                :
                <Link to="/login" className="button is-primary">
                  <strong>Log In</strong>
                </Link>
              }
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
