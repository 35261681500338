import React, { useState, useEffect } from "react";
import pvoptalogo from "../assets/pv-opta-logo.png";
import locationReviewImage from "../assets/undraw_location_review_dmxd.svg";
import { appStorage } from "../../util";
import ModalDialog from "../ModalDialog";
import "./styles.scss";

function Login() {
  document.title = "Login";
  const [loginCredentials, doLogin] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loginCredentials) return;
    const username=loginCredentials.username;
    const password= loginCredentials.password;
    if (username==='' || password==='') return;
    const fetchUserData = async () => {
      await fetch(window.api.login,
        {
          method: "POST",
          body: JSON.stringify({username:username, password:password}),
        }
      )
      .then( response => (response.status===200) ? response.json(): {} )
      .then( (jsonResponse) => {
        console.log("login response: %o", jsonResponse);
        if (jsonResponse.authn_token){
          appStorage.put('userData', jsonResponse);
          appStorage.put('mapZoomLevel', 16);
          window.location.href = "/mapview";
        } else {
          doLogin(null);
          setShowModal(true);
        }
      })
      .catch( error => {
        console.log("login error: %o", error);
      });
    };
    fetchUserData();
  });

  return (
    <>
    {showModal ? ModalDialog(
      {
        caption: <>Login Failed<br/>Click OK to try again</>,
        button2: {
          caption: "OK",
          fnOnClick: ()=>{ setTimeout(setShowModal(false), 100) }
        }
      })
      : []
    }

    <section className="section">
      <div className="columns is-centered">
        <div className="column is-one-quarter">
          <img data-cy="login-logo" className="is-pulled-right" src={pvoptalogo} alt="OPTA" />
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-one-quarter">
          <div className="field">
            <div className="control">
              <input id="username" className="input" type="text" placeholder="Username" />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input id="password" className="input" type="password" placeholder="Password" />
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-one-quarter">
          <div className="columns is-mobile is-vcentered">
            <div className="column">
              <div className="field">
                <label className="label needhelp" onClick={()=>window.location.href="/contact"}>Need Help?</label>
              </div>
            </div>
            <div className="column">
              <div className="field is-pulled-right">
                <div className="control">
                  <button
                    className="button is-primary is-medium"
                    onClick={()=>{
                      const username = document.getElementById("username").value;
                      const password = document.getElementById("password").value;
                      // console.log("Click: u/p:%o %o", username, password);
                      doLogin(
                        {
                          "username": username,
                          "password": password
                        }
                      )
                    }}
                  >
                      Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <img className="is-pulled-right" src={locationReviewImage} alt="location review" width="170" height="153" />
      </footer>
    </section>
    </>
  );
}

export default Login;
