import React from "react";
import Menu from "../Menu";
import "./styles.scss";

function HistoryView() {

  return (
    <>
    <Menu/>
    <section className="section">
    HistoryView placeholder
    </section>
    </>
  );
}

export default HistoryView;
