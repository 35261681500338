import React, { useState } from "react";
import Menu from "../Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faChevronLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { appStorage } from "../../util";
import "./styles.scss";
import Feature from "../Feature";


function PropertyConfirmationView() {
  document.title = "Property Confirmation";
  const [isNavButtonEnabled, setNavButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const property = appStorage.get('selectedProperty');
  const [feature, setFeature] = useState(
    {
      label: "Invalid Property",
      options: [
        "No Clear Property View","Address Not Found","Non-Residential Property", "Other"
      ],
      selected: ""
    }
  );

  return (
    <>
    <Menu/>
    <section className="section the-heading">
      <div className="columns is-mobile is-centered is-vcentered">
        <div className="column opta-orange has-text-centered is-3 is-offset-1">
          <span className="title-camera-icon">
            <FontAwesomeIcon icon={faCamera}/>
          </span>
        </div>
        <div className="column pcnv-title has-text-left std">
            <p>Property ID: {property.sysid}</p>
            <p>{property.streetaddress}</p>
            <p>{property.municipality} {property.province} {property.postalcode}</p>
        </div>

      </div>
      <hr/>
    </section>

    <section className="section pcnv-panel1">
      <div className="columns is-mobile is-centered">
        <div className="column has-text-centered">
          <div className="pcnv-title">
            Confirm Location
          </div>
          <button
            className={`button is-primary is-fullwidth ${isLoading?'is-loading':''}`}
            disabled={isNavButtonEnabled}
            onClick={ () => {
              setIsLoading(true);
              const updateInspectionStatus = async () => {
                fetch(`${window.api.properties}/${property.sysid}`,
                  {
                    method: 'PUT',
                    body: JSON.stringify({inspectionstatus: "Pending"}),
                    headers: new Headers({
                      'Authorization': `Bearer ${appStorage.get('userData').authn_token}`
                    })
                  }
                )
                .catch( (error) => {
                  console.error('Inspection status error: %o', error);
                })
                .finally( () => window.location.href=`/PhotoFeaturesView`);
              };
              updateInspectionStatus();
            }}
          >
            <span>Address Confirmed</span>
          </button>
          <div className="pcnv-panel-text">
            Street number and imagery for property available
          </div>
        </div>
      </div>
    </section>

    <section className="section pcnv-panel2">
      <div className="columns is-mobile is-centered">
        <div className="column has-text-centered">
          <Feature
            feature={feature}
            doUpdateValue={ (label, value)=> {
              console.log("doUpdateValue label:%o value:%o", label, value);
              setNavButton(value!=="");
              feature.selected = value;
              setFeature(feature);

              const reportAnIssue = async () => {
                console.log("feature: %o", feature);
                fetch(`${window.api.properties}/reportissue/${property.sysid}`,
                  {
                    method: 'PUT',
                    headers: new Headers({
                      'Authorization': `Bearer ${appStorage.get('userData').authn_token}`
                    }),
                    body: JSON.stringify({inspectionstatus: "Issue", reason: feature.selected})
                  }
                )
                .catch( (error) => {
                  console.error('Inspection issue error: %o', error);
                })
                // .finally( () => window.location.href=`/MapView`);
              };
              reportAnIssue();
            }}
          />
          <div className="pcnv-panel-text">
          Incorrect location or data acquisition not possible
          </div>
        </div>
      </div>
    </section>

    <section className="section pcnv-panel3">
      <div className="columns is-mobile">
        <div className="column">
          <button
            className="button is-primary is-pulled-left"
            disabled={!isNavButtonEnabled}
            onClick={ () => window.location.href='/MapView' }
          >
            <span className="icon"><FontAwesomeIcon icon={faChevronLeft} /></span>
            <span>Navigate</span>
            <span className="icon"><FontAwesomeIcon icon={faMapMarker} /></span>
          </button>
        </div>
      </div>
    </section>
    </>
  );
}

export default PropertyConfirmationView;
