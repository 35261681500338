import React, { useState, useEffect, useRef } from "react";
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { useGoogleMaps } from "react-hook-google-maps";
import { MAP_API_KEY } from "../../util/constants";
import { appStorage, getPlatform } from "../../util";
import ModalDialog from "../ModalDialog";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import "./styles.scss";

function distance(lat1, lon1, lat2, lon2) {
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
  }
	else {
		let radlat1 = Math.PI * lat1/180;
		let radlat2 = Math.PI * lat2/180;
		let theta = lon1-lon2;
		let radtheta = Math.PI * theta/180;
		let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;

    if (dist < 1){
      return {
        distanceInMeters: parseInt(dist * 1000),
        distanceInKm: parseInt(dist * 10) / 10,
        distanceString: parseInt(dist * 1000) + 'm'
      };
    }
    return {
      distanceInMeters: parseInt(dist * 1000),
      distanceInKm: parseInt(dist * 10) / 10,
      distanceString: parseInt(dist * 10) / 10 + 'Km',
    };
  }
}

function showRoute(myPosition, property){
  if (!myPosition || !property)
    return;

  let urlIntent = `&origin=${myPosition.lat},${myPosition.lng}`;
  urlIntent += `&destination=${property.streetaddress} ${property.municipality} ${property.postalcode}`;

  const platform = getPlatform();
  if (platform==='ios'){
    //IOS experience
    urlIntent = `maps://www.google.com/maps/dir/?api=1${urlIntent}`;
  } else if (platform==='android'){
    //Android experience
    urlIntent = `google.navigation:q=${property.streetaddress} ${property.municipality} ${property.postalcode}`;
  } else {
    //Deskop experience
    urlIntent = `https://www.google.com/maps/dir/?api=1${urlIntent}`;
  }
  urlIntent = encodeURI(urlIntent);
  // console.log("urlIntent: %o", urlIntent);
  window.open(urlIntent);
  //--- Create Navigation Route ---
}

function MapView() {
  document.title = "Map";

  const [showQueuedInspections, setShowQueuedInspections] = useState(true);
  const [showPendingInspections, setShowPendingInspections] = useState(true);

  const [myGeoPosition, setMyGeoPosition] = useState([]);

  const [ajaxAddresses, setAjaxAddresses] = useState([]);
  const [autocompleteOpen, setAutocompleteOpen]  = useState(false);
  let autocompleteDebounce = null;
  const autocompleteLoading = autocompleteOpen && ajaxAddresses.length===0;

  const markerClustererQueued = useRef(null);
  const markerClustererPending = useRef(null);

  const isMapViewDirty = useRef(false);
  const isHandlerActive = useRef(false);

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState();

  const getAjaxAddresses=(property)=>{
        fetch(window.api.addressSearch, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${appStorage.get('userData').authn_token}`
      }),
      body: JSON.stringify(
        {
          StreetAddress : `${property.streetaddress}`,
          Province : `${property.province}`,
          authz_token: appStorage.get('userData').authz_token,
          Username: "pvplus.inspector1@scm.ca"
        }
      )
    })
    .then(response => response.json())
      .then(responseJson => {
      // console.log("getAjaxAddresses: %o", responseJson);
      if (responseJson['result']){
        const addresses = responseJson['result'];
        if (addresses.length > 10)
          addresses.length=10
        setAjaxAddresses(addresses);
      }
      else
        setAjaxAddresses([]);
    })
  };

  //+++ Handler for GO button +++
  document.clickedGo = (property) =>{
    appStorage.put('selectedProperty', property);
    appStorage.put('myPosition', {lat: myGeoPosition.lat, lng: myGeoPosition.lng});

    showRoute({lat: myGeoPosition.lat, lng: myGeoPosition.lng}, property);
    window.location.href="/PropertyConfirmationView";
  }
  //--- Handler for GO button ---

  //+++ Instantiate Google Map Object +++
  //const { ref, map, google } = useGoogleMaps(MAP_API_KEY[window.env]);
  // console.log("appStorage.get('mapZoomLevel', 16)=%o", appStorage.get('mapZoomLevel', 16));
  const myMap = useGoogleMaps(MAP_API_KEY[window.env],
    {
      gestureHandling: "greedy",
      zoom: appStorage.get('mapZoomLevel', 16)/*16,
      center: {lat: 43.65, lng:-79.38}*/
    }
  );
  //--- Instantiate Google Map Object ---

  //+++ Fetch my location, then list of properties +++
  const [properties, setProperties] = useState();

  const prevMapCenter = useRef({lat:0, lng:0});
  const prevViewDistance = useRef(0);
  const prevMyLocation = useRef({lat:0, lng:0});
  const countFetchPropertyData = useRef(0);
  const countWatchLocation = useRef(0);
  const idleSince = useRef(0);

  const fetchPropertyData = async (myLocation, radius) => {
    console.log("fetchPropertyData(%o, %o)", myLocation, radius);
    if (radius < 0.25){
      radius = 0.25;
    }
    countFetchPropertyData.current = countFetchPropertyData.current + 1 ;
    const rawResponse = await fetch(`${window.api.properties}?latitude=${myLocation.lat}&longitude=${myLocation.lng}&radius=${radius}`, {
       method: 'GET',
       headers: new Headers({
        'Authorization': `Bearer ${appStorage.get('userData').authn_token}`
      })
    });
    const response = await rawResponse.json();
    // console.log("fetchPropertyData response: %o", response);
    setProperties(response);
  };

  const propertyMarkersQueued = useRef([]);
  const propertyMarkersPending = useRef([]);
  const inspectorMarker = useRef(null);

  const getContent = (property, myGeoPosition)=>{
    const iwLeftClass = `iw-left`; // ${property.inspectionstatus === 'Queued' ? "" : "hideme"}`;
    const content = `<div class='info-window'>` +
    `<div class='${iwLeftClass}'>` +
      `<div class='iw-btn' onclick='document.clickedGo(${JSON.stringify(property)})'>` +
        `<img class='iw-img' />` +
      `</div>`+
    `</div>` +
    `<div class='iw-right'>` +
      `<div class='iw-title'>${property.streetaddress}</div>` +
      `<div class='iw-dist'>#${property.sysid}# ${distance(myGeoPosition.lat, myGeoPosition.lng, property.latitude, property.longitude).distanceString}</div>` +
    `</div>` +
  '</div>';
    return content;
  };

  useEffect( () => {
    // console.log("EFFECT A");

    let bActivate = true;
    if (!properties){
      // console.log("Properties is empty");
      bActivate = false;
    } else {
      // console.log("Properties is OK");
    }

    if (!myMap.map){
      // console.log("myMap.map is empty");
      bActivate = false;
    } else {
      // console.log("myMap.map is OK");
    }

    if (!myGeoPosition){
      // console.log("myGeoPosition is empty");
      bActivate = false;
    } else {
      // console.log("myGeoPosition is OK");
    }

    if (!bActivate) return;

    if (properties && myMap.map && myGeoPosition){
      console.log("EFFECT A - Active");

      //remove existing markers from map
      if (markerClustererQueued.current != null)
        markerClustererQueued.current.clearMarkers();
      if (markerClustererPending.current != null)
      markerClustererPending.current.clearMarkers();

      //clear the marker array
      propertyMarkersQueued.current.length = 0;
      propertyMarkersPending.current.length = 0;

      //+++ Create Inspector Marker +++
      if (inspectorMarker.current)
        inspectorMarker.current.setMap(null);
      const inspectorPosition = {lat: myGeoPosition.lat, lng: myGeoPosition.lng};

      inspectorMarker.current = new myMap.google.maps.Marker(
        {
          position: inspectorPosition,
          icon: {
            url: "/clustermarkers/woman.png",
            scaledSize: new myMap.google.maps.Size(32,32),
          },
          map: myMap.map
        }
      );
      //--- Create Inspector Marker ---

      //+++ Create Property Markers +++
      properties.forEach( property => {
        if (!property.latitude || !property.longitude)
          return;

        const ll = new myMap.google.maps.LatLng(parseFloat(property.latitude), parseFloat(property.longitude));
        let icon;

        switch (property.inspectionstatus){
          case "Queued":
            icon = "/clustermarkers/red-dot.png";
            break;
          case "Pending":
            icon = "/clustermarkers/yellow-dot.png";
            break;
          default:
        }

        const pm = new myMap.google.maps.Marker(
          {
            position: ll,
            icon: icon
          }
        );

        const contentInfoWindow = getContent(property, myGeoPosition);
        const infoWindow = new myMap.google.maps.InfoWindow({content: contentInfoWindow});
        pm.addListener("click", ()=>{
          idleSince.current = Date.now();
          if (infoWindow.isOpen){
            infoWindow.close();
            infoWindow.isOpen = false;
          } else {
            infoWindow.open(myMap.map, pm);
            infoWindow.isOpen = true;
          }
        })

        switch (property.inspectionstatus){
          case "Queued":
            if (showQueuedInspections)
              propertyMarkersQueued.current.push(pm);
            break;
          case "Pending":
            if (showPendingInspections)
              propertyMarkersPending.current.push(pm);
            break;
          default:
        }
      })
      //--- Create Property Markers ---

      markerClustererQueued.current = new MarkerClusterer(myMap.map, propertyMarkersQueued.current, {
        imagePath: "/clustermarkers/marker-queued",
        averageCenter: true,
        zoomOnClick: true
      });

      markerClustererPending.current = new MarkerClusterer(myMap.map, propertyMarkersPending.current, {
        // imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        imagePath: "/clustermarkers/marker-pending",
        averageCenter: true,
        zoomOnClick: true,

      });

      markerClustererQueued.current.addListener("click", () => {
        // console.log("Marker Clusterer Click()");
        isHandlerActive.current = true;
      })
      markerClustererPending.current.addListener("click", () => {
        // console.log("Marker Clusterer Click()");
        isHandlerActive.current = true;
      })

    }
    // console.log("useEffect() A - END");
  }, [myMap, properties, myGeoPosition, showQueuedInspections, showPendingInspections]);

  useEffect(()=>{
    // console.log("MYMAP.MAP CHANGED!! :%o", myMap.map);
    if (!myMap.map)
      return;

    myMap.map.addListener("dragend", ()=> {
        // console.log("MAP event: dragend A %o, %o", isHandlerActive.current, isMapViewDirty.current);
        if (!isHandlerActive.current){
          isMapViewDirty.current = true;
        }
      });

    myMap.map.addListener("zoom_changed", (e)=> {
      // console.log("MAP event: zoom_changed %o", myMap.map.getZoom());
      appStorage.put('mapZoomLevel', myMap.map.getZoom());
      if (!isHandlerActive.current){
        isMapViewDirty.current = true;
      }

    });

    myMap.map.addListener("idle", ()=> {
      if (isMapViewDirty.current){
        const bounds = myMap.map.getBounds();
        // console.log("MAP event: idle - dirtyView bounds: %o", bounds);
        if (!bounds)
          return;
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const ctr = bounds.getCenter();

        //Use diagonal distance of map as radius.
        const viewDistance = distance(ne.lat(), ne.lng(), sw.lat(), sw.lng()).distanceInKm;

        const mapCenter = {
          lat: ctr.lat(),
          lng: ctr.lng()
        }
        // console.log("MAP event: idle A - dirtyView viewDistance: %o %o", viewDistance, mapCenter);
        isMapViewDirty.current = false;
        isHandlerActive.current = false;

        //Only fetch property data if:
        // mapcenter has changed (Zoomed out) ie. deltaViewDistance > 0, OR
        // viewDistance has changed
        const deltaMapCenterX = Math.abs(mapCenter.lat - prevMapCenter.current.lat);
        const deltaMapCenterY = Math.abs(mapCenter.lng - prevMapCenter.current.lng);
        const deltaMapCenter = deltaMapCenterX + deltaMapCenterY;

        const deltaViewDistance = viewDistance - prevViewDistance.current;

        // console.log("deltaMapCenter: %o", deltaMapCenter);
        // console.log("deltaViewDistance: %o", deltaViewDistance);

        if ((deltaViewDistance > 0) || (deltaMapCenter > 0.0025)){
          //zoom has changed OR map has panned
          prevMapCenter.current = mapCenter;
          prevViewDistance.current = viewDistance;

          // console.log("Fetching property data");
          fetchPropertyData(mapCenter, (viewDistance / 3.2));
        }
      }
      // console.log("MAP event: idle C");
      setTimeout(()=>{
        // console.log("MAP event: idle C DONE");
        isHandlerActive.current = false;
        idleSince.current = Date.now();
      }, 10);
    });
  }, [myMap.map, prevMapCenter, prevViewDistance, idleSince])

  useEffect(() => {
    if (!myMap.map)
      return;
    navigator.geolocation.watchPosition(
      (pos) => {
        const myLocation = {lat: pos.coords.latitude, lng: pos.coords.longitude};
        countWatchLocation.current = countWatchLocation.current + 1;
        // console.log("WatchLocation() myLocation: %o", myLocation);

        const deltaMyLocationX = Math.abs(myLocation.lat - prevMyLocation.current.lat);
        const deltaMyLocationY = Math.abs(myLocation.lng - prevMyLocation.current.lng);
        const deltaMyLocation = deltaMyLocationX + deltaMyLocationY;

        if (myMap.map && deltaMyLocation > 0.0005){
          const elapsedTime = (Date.now() - idleSince.current);
          // console.log("WatchLocation() elapsedTime: %o", elapsedTime);
          if (elapsedTime > 10000){
            idleSince.current = Date.now();
            prevMyLocation.current = myLocation;
            setMyGeoPosition(myLocation);
            isMapViewDirty.current = true;
            myMap.map.panTo(myLocation);
          }
        }
      },
      (err) => {
        console.log("Navigtor watchPosition() API err: %o", err);
      },
      { //settings
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0
      });

    navigator.geolocation.getCurrentPosition(
      (pos) => { //onChange
        const myLocation = {lat: pos.coords.latitude, lng: pos.coords.longitude};
        // console.log("getCurrentPosition(): %o", myLocation);
        setMyGeoPosition(myLocation);
        if (myMap.map)
          myMap.map.setCenter(myLocation, 3);
      },
      (err) => { //onError
        console.log("Navigtor API err: %o", err);
      },
      { //settings
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0
      }
    );
  }, [myMap.map]);

  return (
    <>
    {showModal ? ModalDialog(modal) : [] }
    <section className="map-section">
      <div className="columns is-centered is-multiline is-mobile">
        <div className="column is-full">
          <div id="idmap" ref={myMap.ref}>
          </div>
        </div>

        <div className="column is-half for-checkbox">
          <label className="checkbox" >
            <input type="checkbox" onChange={(e)=>{
              setShowQueuedInspections(e.target.checked);
            }}
            checked={showQueuedInspections}
            />
            &nbsp;Queued
             {/* #{countFetchPropertyData.current} #{countWatchLocation.current} */}
          </label>
        </div>

        <div className="column is-half for-checkbox">
          <label className="checkbox">
            <input type="checkbox" onChange={(e)=>{
              setShowPendingInspections(e.target.checked);
            }}
            checked={showPendingInspections}
            />
            &nbsp;Pending
            {/* {myGeoPosition.lat} {myGeoPosition.lng} */}
          </label>
        </div>
      </div>

      <hr/>

      <div className="columns is-centered">
        <div id="idpanel" className="column">
          Select a property on the map<br/>
          OR
        </div>
      </div>

      <div className="columns is-centered">
        <div className="column is-four-fifths">
          <Autocomplete
            // freeSolo
            id="combo-box-demo"

            loading={autocompleteLoading}
            loadingText={'Type an address...'}
            open={autocompleteOpen}
            onOpen={()=>{setAutocompleteOpen(true);}}
            onClose={()=>{setAutocompleteOpen(false);}}

            options={ajaxAddresses}
            getOptionLabel={(option) => {
              if (!option.StreetName) return option;
              let addr = "";
              if (option.UnitNumber)
                addr += option.UnitNumber + "-" + option.StreetNumber;
              else
                addr += option.StreetNumber;

              addr += " " + option.StreetName;
              addr += " " + option.StreetType;
              if (option.StreetDirection)
                addr += " " + option.StreetDirection;
              addr += " " + option.Municipality;
              addr += " " + option.Province;
              addr += " " + option.PostalCode;
              return addr;
            }}
            onChange={(e, v, reason)=>{
              // console.log("AC onChange v: %o reason:%o", v, reason);
              if (reason==='select-option'){
                fetch(window.api.properties + '/createAddressEntry', {
                  method: 'POST',
                  mode: 'cors',
                  cache: 'no-cache',
                  credentials: 'same-origin',
                  headers: new Headers({
                    'Authorization': `Bearer ${appStorage.get('userData').authn_token}`,
                    'Content-Type': 'application/json'
                  }),
                  redirect: 'follow',
                  referrerPolicy: 'no-referrer',
                  body: JSON.stringify( {...v, authz_token: appStorage.get('userData').authz_token} )
                })
                .then( response => (response.status===200) ? response.json(): {} )
                .then(responseJson => {
                  // console.log('Success: %o', responseJson);
                  if (responseJson.msg === "not available"){
                    setModal({
                      caption: <>Address not available for inspection.</>,
                      button2: {
                        caption: "OK",
                        fnOnClick: ()=>{setShowModal(false)}
                      }
                    });
                    setShowModal(true);
                  } else {
                    let caption = `Address #${responseJson.property.sysid}# selected for inspection.`;
                    if (responseJson.msg === 'inserted'){
                      caption = `Address #${responseJson.property.sysid}# created for inspection.`;
                    }
                    setModal({
                      caption: <>{caption}</>,
                      button1: {
                        caption: "Back",
                        fnOnClick: ()=>{
                          setShowModal(false);
                        }
                      },
                      button2: {
                        caption: "OK",
                        fnOnClick: ()=>{
                          setShowModal(false);
                        }
                      }
                    });
                    isMapViewDirty.current = true;
                    myMap.map.setCenter({lat:responseJson.property.latitude, lng:responseJson.property.longitude}, 20);
                    setShowModal(true);
                  }
                });

                return;
              } else if (reason==='create-option'){
                setAjaxAddresses([]);
                setModal({
                  caption: <>Searched address not available</>,
                  button2: {
                    caption: "OK",
                    fnOnClick: ()=>{
                      setShowModal(false);
                    }
                  }
                });
                setShowModal(true);
                return;
              }
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Enter an address"
                variant="outlined"
                onChange={(e)=>{
                  const v = e.target.value.replace(/[0-9 ]/g, '');
                  if (v.length > 3){
                    if (autocompleteDebounce !== null)
                      clearTimeout(autocompleteDebounce);
                      const strAddr = e.target.value;
                    autocompleteDebounce = setTimeout(
                      ()=>{getAjaxAddresses({ streetaddress: strAddr, province: ""});}
                      , 250
                    );
                  }
                }}

              />
            }
          />
        </div>
      </div>

    </section>
    </>
  );
}

export default MapView;
