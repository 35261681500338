import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCheckCircle, faHome, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { appStorage, getPlatform } from "../../util";
import ModalDialog from "../ModalDialog";

import "./styles.scss";

function PhotoCaptureButton(buttonCaption, isSubmit, fnSetSubmit, fnSetPreview, srcPreview){
  const photoId = buttonCaption.trim().toLowerCase().replace(" ", "");
  const elId = "fi-" + photoId; //id for file input

  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isFileInMemory, setIsFileInMemory] = useState(false);
  const [previewPanelSrc, setPreviewPanelSrc] = useState(srcPreview);

  // console.log("PhotoCaptureButton %o ReRendering: %o", photoId, srcPreview);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const fileSelectionChanged = (e) => {
    if (!e.target.files[0]) return;
    const fileExtension = e.target.files[0].name.split('.').pop();
    if (!["png", "jpeg", "jpg"].includes(fileExtension)){
      console.log("Unsupported file format");
      setShowErrorModal(true);
      return true;
    }
    // console.log("FileSelected: %o", e.target.files[0]);

    //+++ Set Preview image src +++
    let reader = new FileReader();
    reader.onload = (e)=>{
      // console.log("setting previewPanelSrc to: %o", e.target.result.slice(0,40));
      setPreviewPanelSrc(e.target.result);
      if (fnSetPreview)
        fnSetPreview(e.target.result);
    }
    reader.readAsDataURL(e.target.files[0]);
    //--- Set Preview image src ---

    e.target.setAttribute("is-dirty","true");
    setIsFileSelected(true);
    setIsFileInMemory(true);
    fnSetSubmit(true);
  }

  if (previewPanelSrc){
    if (!isFileInMemory) setIsFileInMemory(true);
    if (!isSubmit) fnSetSubmit(true);
  }

  const previewPanel = <div
         className={`column preview-panel ${(isFileInMemory||srcPreview) ? "file-in-memory" : ""} ${isFileSelected ? "file-selected" : ""}`}
    >
    <div
      id = {`thumbnail-${photoId}`}
      className={`image-thumbnail `}
      style={{ backgroundImage: `url(${previewPanelSrc?previewPanelSrc:srcPreview})`}}
    />

  </div>

  return(
    <>

    {showErrorModal ? ModalDialog(
      {
        caption: <>Unsupported file format.</>,
        button2: {
          caption: "OK",
          fnOnClick: ()=>{setShowErrorModal(false);}
        }
      })
      : []
    }

    <div
    id={"div-"+photoId}
    className={`columns is-mobile is-clickable pv-actionable-row is-gapless is-multiline p-2 mb-2 photo-button ${isFileSelected ? "file-selected" : ""}`}
    onClick={(e) => {
      // console.log("take pic event: %o", elId);
      const el = document.getElementById(elId);
      el.click();
      e.preventDefault();
    }}
    >
      <div className="column is-9 is-size-6 has-text-left caption">
        {buttonCaption}
      </div>
      <div className="column is-3 has-text-right">
        <span className="icon camera">
          <FontAwesomeIcon icon={faCamera}/>
        </span>
        <span className="icon check">
          <FontAwesomeIcon icon={faCheckCircle}/>
        </span>

      </div>
    </div>
    {getPlatform()==="ios"
      ?
      <input
        type="file"
        name={photoId}
        id={"fi-"+photoId}
        className="photofileinput"
        onChange={fileSelectionChanged}
        accept="image/*"
      />
      :
      <input
        type="file"
        name={photoId}
        id={"fi-"+photoId}
        className="photofileinput"
        onChange={fileSelectionChanged}
        accept="image/jpeg,image/jpg,image/gif,image/png" capture="camera"
      />
    }
    {previewPanel}
    </>
  )
}

function PhotoCaptureView(props) {
  document.title = "Photo Capture";
  const [isSubmitEnabled, setSubmitEnabled] = useState(false);
  const [showBackModal, setShowBackModal] = useState(false);

  const [srcFrontView, setSrcFrontView] = useState("");
  const [srcLeftView, setSrcLeftView] = useState("");
  const [srcRightView, setSrcRightView] = useState("");
  const [srcOtherView, setSrcOtherView] = useState("");

  const propertyId = appStorage.get('selectedProperty', {})['sysid'];

  useEffect(()=>{
    // console.log("ONE TIME RUN: PhotoCaptureView");
    fetch(window.api.photos + `/${propertyId}`, { //get presigned URLs for pulling S3 images
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${appStorage.get('userData').authn_token}`
      })
    })
    .then(response => {
      return response.json();
    })
    .then(response => {
      const data=response.data;
      if (data.frontview){
        props.setSrcPreview(data.frontview);
        setSrcFrontView(data.frontview);
      }
      if (data.leftview) setSrcLeftView(data.leftview);
      if (data.rightview) setSrcRightView(data.rightview);
      if (data.otherview) setSrcOtherView(data.otherview);
    });

  }, [propertyId, props])

  const doSubmit = () => {
    setSubmitEnabled(false);
    let numActiveFetches = 0;

    const elPhotoFileInputs = document.getElementsByClassName("photofileinput");
    for (const elPhotoFileInput of elPhotoFileInputs) {
      if (!elPhotoFileInput.getAttribute("is-dirty"))
        continue;
      elPhotoFileInput.removeAttribute("is-dirty");

      const photoFile=elPhotoFileInput.files[0];
      props.setSpinner(true);

      const photoFileName=elPhotoFileInput.name;
      const photoFileExtension = photoFile.name.split('.').pop();
      const fileFullPathName = `${propertyId}/${photoFileName}.${photoFileExtension}`.toLowerCase();

      //console.log("photoFileName: %o", photoFileName);

      numActiveFetches++;
      fetch(window.api.photos, { //get presigned URL
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${appStorage.get('userData').authn_token}`
        }),
        body: JSON.stringify(
          {
            "filename" : fileFullPathName,
            "filetype" : photoFile.type
          }
        )
      })
      .then(response => response.json())
      // eslint-disable-next-line no-loop-func
      .then(presignedPostData => {
        //console.log("presignedPostData: %o", presignedPostData);
        const formData = new FormData();
        Object.keys(presignedPostData.data.fields).forEach(key => formData.append(key, presignedPostData.data.fields[key]));
        formData.append("file", photoFile);

        fetch(presignedPostData.data.url, { //Upload to S3
          method: 'POST',
          body: formData
        })
        .then(response => {
          // console.error('API S3 Upload OK: %o', response);
          if (--numActiveFetches === 0){
            setSubmitEnabled(true);
            props.setSpinner(false);
          }
        })
        .catch( (error) => {
          console.error('API S3 Upload Error: %o', error);
          if (--numActiveFetches === 0){
            setSubmitEnabled(true);
            props.setSpinner(false);
          }
        });
      })
      // eslint-disable-next-line no-loop-func
      .catch((error) => {
        console.error('API Photos Error: %o', error);
        if (--numActiveFetches === 0){
          setSubmitEnabled(true);
          props.setSpinner(false);
        }
      });
    }
    props.goNext();
  }

  return (
  <section id="id-photo-capture-view">
    {showBackModal ? ModalDialog(
      {
        caption: <>All updates will be lost if you go back to <i>Property Confirmation</i> page</>,
        button1: {
          caption: "OK",
          fnOnClick: ()=>{window.location.href="/PropertyConfirmationView"}
        },
        button2: {
          caption: "Cancel",
          fnOnClick: ()=>{setShowBackModal(false);}
        }
      })
      : []
    }
    <section className="section the-heading">
      <div className="columns is-mobile is-centered is-vcentered">
        <div className="column title-camera-icon opta-orange is-3">
          <span><FontAwesomeIcon icon={faCamera}/></span>
        </div>
        <div className="column">
          <span className="pcv-title" >Image Capture</span>
        </div>
      </div>
    </section>

    <section className="section photo-buttons">
      <div className="the-photo-buttons">
                            {/* buttonCaption, isSubmit, fnSetSubmit, fnSetPreview */}
        {PhotoCaptureButton("FRONT VIEW", isSubmitEnabled, setSubmitEnabled, props.setSrcPreview, srcFrontView)}
        {PhotoCaptureButton("LEFT VIEW", isSubmitEnabled, setSubmitEnabled, null, srcLeftView )}
        {PhotoCaptureButton("RIGHT VIEW", isSubmitEnabled, setSubmitEnabled, null, srcRightView )}
        {PhotoCaptureButton("OTHER VIEW", isSubmitEnabled, setSubmitEnabled, null, srcOtherView )}
      </div>
      <div className="sub-text">
        Front, Left and Right Views are required
      </div>
    </section>

    <section className="section">
      <div className="columns is-mobile">
        <div className="column">
            <button
              className="button is-primary is-pulled-left"
              onClick={ () => setShowBackModal(true) }
            >
              <span className="icon"><FontAwesomeIcon icon={faChevronLeft} /></span>
              <span>Back</span>
            </button>

            <button
              className="button is-primary is-pulled-right test"
              onClick={ () => doSubmit() }
              id="id-submit"
              disabled={!isSubmitEnabled && (srcFrontView==="")}
            >
              <span className="icon"><FontAwesomeIcon icon={faHome} /></span>
              <span>Enter Info</span>
              <span className="icon"><FontAwesomeIcon icon={faChevronRight} /></span>
            </button>
        </div>
      </div>
    </section>
  </section>
  );
}

export default PhotoCaptureView;
