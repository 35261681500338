import React from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import ContactView from "./ContactView";
import HistoryView from "./HistoryView";
import LoginView from "./LoginView";
import LogoutView from "./LogoutView";
import MapView from "./MapView";
import PropertyConfirmationView from "./PropertyConfirmationView";

// import PropertyView from "./PropertyView";
// import PhotoCaptureView from "./PhotoCaptureView";

import PhotoFeaturesView from "./PhotoFeaturesView";
import SearchView from "./SearchView";

import { isSessionValid, setApiEndpoints } from '../util';

function App() {
  setApiEndpoints();  //auto provision API endpoints
  return (
    <Switch>
      <Route exact path="/" component={LoginView} />
      <Route exact path="/login" component={LoginView} />
      <Route exact path="/logout" component={LogoutView} />
      <Route exact path="/contact" component={ContactView} />

      <PrivateRoute exact path="/mapview" component={MapView} />
      <PrivateRoute exact path="/PhotoFeaturesView" component={PhotoFeaturesView} />
      {/* <PrivateRoute exact path="/propertyview" component={PropertyView} /> */}
      <PrivateRoute exact path="/PropertyConfirmationview" component={PropertyConfirmationView} />

      <PrivateRoute exact path="/history" component={HistoryView} />
      <PrivateRoute exact path="/search" component={SearchView} />

      {/* <Route path="*" component={LoginView}></Route> */}
      <Route path="*"><NoMatch/></Route>
    </Switch>
  );
}

const NoMatch = () => {
  let location = useLocation();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props => (
          isSessionValid()
          ? <Component {...props} />
          : <Redirect to="/login" />
      )}
  />
);

export default App;
