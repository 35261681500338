import React, {useEffect, useState} from "react";
import PropertyView from "../PropertyView";
import Menu from "../Menu";
import PhotoCaptureView from "../PhotoCaptureView";
import "./styles.scss";

function PhotoFeaturesView(props) {
  const [activeView, setActiveView] = useState("photo-capture");
  const [srcPreview, setSrcPreview] = useState();
  const [isSpinning, setSpinner] = useState(false);

  useEffect( ()=>{
    if (activeView === "property-features")
      document.title = "Construction Features";
    else if (activeView === "photo-capture")
      document.title = "Photo Capture";
  }, [activeView])

  return (
    <section className={`photo-features ${activeView}`} >
      <Menu isSpinning={isSpinning}/>
      <section className="photo-capture">
        <PhotoCaptureView
           goNext={()=>setActiveView("property-features")}
           setSrcPreview={setSrcPreview}
           setSpinner={setSpinner}
          />
      </section>

      <section className="property-features">
        <PropertyView
          goPrevious={()=>setActiveView("photo-capture")}
          srcPreview={srcPreview}
        />
      </section>
    </section>
  );
}

export default PhotoFeaturesView;
