export const MAP_API_KEY={
  local: "AIzaSyCj3il29siy1OGMH7BW1V0AiabTNyQGo64",
  dev: "AIzaSyCj3il29siy1OGMH7BW1V0AiabTNyQGo64",
  test: "AIzaSyCj3il29siy1OGMH7BW1V0AiabTNyQGo64",
  prod: "AIzaSyCj3il29siy1OGMH7BW1V0AiabTNyQGo64"
}

const FEATURE_TYPE = {
  SINGLE_OPTION: "SINGLE_OPTION",
  TEXT: "TEXT",
  INTEGER: "INTEGER",
  READ_ONLY: "READ_ONLY",
};

export const INIT_FEATURES = [
  {
    label: "Year Built",
    id: "YearBuilt",
    type: FEATURE_TYPE.SINGLE_OPTION,
    options: [],
  },
  {
    label: "Storeys",
    id: "ResidentialStoreyCountType",
    type: FEATURE_TYPE.SINGLE_OPTION,
    options: [],
  },
  {
    label: "Style",
    id: "ArchitecturalStyleType",
    type: FEATURE_TYPE.SINGLE_OPTION,
    options: [],
  },
  {
    label: "Foundation",
    id: "FoundationType",
    type: FEATURE_TYPE.SINGLE_OPTION,
    options: [],
  },
  {
    label: "Exterior Wall",
    id: "ExteriorWallType",
    type: FEATURE_TYPE.SINGLE_OPTION,
    options: [],
  },
  {
    label: "Roof",
    id: "RoofType",
    type: FEATURE_TYPE.SINGLE_OPTION,
    options: [],
  },
  {
    label: "Garage Type",
    id: "GarageType",
    type: FEATURE_TYPE.SINGLE_OPTION,
    options: [],
  },
  {
    label: "Garage - number of cars",
    id: "GarageNumberOfCars",
    type: FEATURE_TYPE.SINGLE_OPTION,
    options: [],
  },
  {
    label: "Builder (if applicable)",
    id: "Builder",
    type: FEATURE_TYPE.TEXT,
    options: [],
  },
  {
    label: "Square Footage (measured)",
    id: "sqft",
    type: FEATURE_TYPE.INTEGER,
    options: [],
  },
];
