import React from "react";
import Menu from "../Menu";
import "./styles.scss";

function SearchView() {

  return (
    <>
    <Menu/>
    <section className="section">
    SearchView placeholder
    </section>
    </>
  );
}

export default SearchView;
