import React from "react";
import Menu from "../Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";
import locationReviewImage from "../assets/undraw_location_review_dmxd.svg";

import "./styles.scss";

function ContactView() {

  return (
    <>
    <Menu/>
    <section className="section contact-view">
      <div className="columns is-mobile has-text-right is-centered is-vcentered  zero-pad-bottom">
        <div className="column zero-pad-bottom">
          <span className="contact-text-r" >PV+&nbsp;</span>
        </div>
      </div>

      <div className="columns is-mobile is-centered is-vcentered zero-pad-bottom">
        <div className="column zero-pad-bottom">
          <span className="contact-text-l" >Contact</span>
        </div>
      </div>
      <hr/>


      <div className="columns is-mobile is-centered is-vcentered"
        onClick={()=>{ window.open("mailto:info@optaintel.ca?subject=PV+ Help"); } }
      >
        <div className="column mail-icon is-3">
          <span><FontAwesomeIcon icon={faEnvelope}/></span>
        </div>
        <div className="column">
          <span className="mail-text" >info@optaintel.ca</span>
        </div>
      </div>

      <div className="columns is-mobile is-centered is-vcentered"
        onClick={()=>{ window.open("tel:1-877-244-9437"); } }
      >
        <div className="column phone-icon is-3">
          <span><FontAwesomeIcon icon={faPhoneSquareAlt}/></span>
        </div>
        <div className="column">
          <span className="phone-text" >1.877.244.9437</span>
        </div>
      </div>

      <div className="bottom-logo">
        <img className="is-pulled-right" src={locationReviewImage} alt="location review" width="170" height="153" />
      </div>
    </section>
    </>
  );
}

export default ContactView;
