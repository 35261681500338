export const mapsSelector = ()=>{
    if /* if we're on iOS, open in Apple Maps */
      ((navigator.platform.indexOf("iPhone") !== -1) ||
       (navigator.platform.indexOf("iPad") !== -1) ||
       (navigator.platform.indexOf("iPod") !== -1))
      return "maps://maps.google.com/maps?daddr=<lat>,<long>&amp;ll=";
  else /* else use Google */
      return "https://maps.google.com/maps?daddr=<lat>,<long>&amp;ll=";
}

// LFC: +++ Application Scope Global Key Value Store +++
export const appStorage = {
    put: (k, v) => {
        //console.log("put %o, %o", k, v);
        if (typeof v === 'object'){
            window.localStorage.setItem(k, JSON.stringify({ metadata: "object", v: v}));
            //console.log("put storing as object");
        } else {
            window.localStorage.setItem(k, JSON.stringify({ metadata: "primitive", v: v}));
            //console.log("put storing as primitive");
        }
    },
    get: (k, vIfNull) => {
        //console.log("get %o", k);
        let v = vIfNull;
        const jsonv = window.localStorage.getItem(k);
        //console.log("get jsonv: %o", jsonv);
        if (jsonv) {
            let jsonvp = JSON.parse(jsonv);
            //console.log("get jsonvp: %o", jsonvp);
            v = jsonvp.v;
        }
        //console.log("get v: %o", v);
        return v;
    },
    delete: (k) => {
        //console.log("remove %o", k);
        window.localStorage.removeItem(k);
    },
    clear: () => {
        window.localStorage.clear();
    }
}
// LFC: --- Application Scope Global Key Value Store ---



// LFC: +++ Auto-Provision API endpoints +++
export const setApiEndpoints = (strGuiDomain) => {
    const l=window.location;

    if (l.href.match(/localhost:3000/gi)){  //local PC
        window.env = "local";
        window.api = { //LOCAL and DEV
            "login" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/auth/login",
            "validate" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/auth/validate-token",
            "addressSearch" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/properties/addressSearch",
            "features" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/features",
            "properties" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/properties",
            "inspections" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/inspections",
            "photos" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/photos"
        };

        // window.api = { //TEST
        //     "login" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/auth/login",
        //     "validate" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/auth/validate-token",
        //     "addressSearch" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/properties/addressSearch",
        //     "features" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/features",
        //     "properties" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/properties",
        //     "inspections" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/inspections",
        //     "photos" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/photos"
        // };

        // window.api = { //PROD
        //     "login" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/auth/login",
        //     "validate" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/auth/validate-token",
        //     "addressSearch" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/properties/addressSearch",
        //     "features" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/features",
        //     "properties" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/properties",
        //     "inspections" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/inspections",
        //     "photos" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/photos"
        // };

    } else if (l.href.match(/d21wfe7y6kycyo/gi)){    //DEV GUI https://test.d21wfe7y6kycyo.amplifyapp.com/
        window.env = "dev";
        window.api = {
            "login" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/auth/login",
            "validate" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/auth/validate-token",
            "addressSearch" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/properties/addressSearch",
            "features" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/features",
            "properties" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/properties",
            "inspections" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/inspections",
            "photos" : "https://5b5lrv7135.execute-api.ca-central-1.amazonaws.com/dev/photos"
        };
    } else if (l.href.match(/d299vfm4xrmivv/gi) || l.href.match(/pvplus-test\.optaintel/gi)){   //TEST GUI https://d299vfm4xrmivv.cloudfront.net/
        window.env = "test";
        window.api = {
            "login" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/auth/login",
            "validate" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/auth/validate-token",
            "addressSearch" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/properties/addressSearch",
            "features" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/features",
            "properties" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/properties",
            "inspections" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/inspections",
            "photos" : "https://5wk8ssw8h4.execute-api.ca-central-1.amazonaws.com/test/photos"
        };

    } else if (l.href.match(/d3hykwf4jtqq20/gi) || l.href.match(/pvplus\.optaintel/gi)){       //PROD GUI https://d3hykwf4jtqq20.cloudfront.net/
        window.env = "prod";
        window.api = {
            "login" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/auth/login",
            "validate" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/auth/validate-token",
            "addressSearch" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/properties/addressSearch",
            "features" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/features",
            "properties" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/properties",
            "inspections" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/inspections",
            "photos" : "https://ccpt5ijhbh.execute-api.ca-central-1.amazonaws.com/prod/photos"
        };
    }

    //console.log("API: %o", window.api);
}
// LFC: --- Auto-Provision API endpoints ---

// LFC: +++ Authentication and Session Management +++
export const isSessionValid = async () => {
    try{
        const ls = appStorage.get('userData');
        const sessionToken = ls.authn_token;

        const urlValidate = `${window.api.validate}/${sessionToken}`;
        return fetch(urlValidate, { method: 'GET'})
        .then( response => {
            if (response.status === 200)
                return true;

            console.log("isSessionValid FALSE A");
            appStorage.clear();
            window.location.href = '/logout';
        });
    } catch(e) {
        console.log("isSessionValid FALSE B");
        appStorage.clear();
        window.location.href = '/logout';
    }
}

export const isLoggedIn = () => {
    const ls = appStorage.get('userData');
    if (ls)
        return true;
    return false;
}
// LFC: --- Authentication and Session Management ---

export const  getPlatform = () => {
    // console.log("platform: %o", navigator.platform);
    // console.log("userAgent: %o", navigator.userAgent);
    if ((navigator.userAgent.indexOf("iPhone") !== -1) ||
       (navigator.userAgent.indexOf("iPad") !== -1) ||
       (navigator.userAgent.indexOf("iPod") !== -1))
    {
      return "ios";
    }

    if (navigator.userAgent.indexOf("Android") !== -1)
      return "android";

    return "desktop";
  }
  