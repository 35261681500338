import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCloudUploadAlt, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { appStorage } from "../../util";
import "./styles.scss";
import Feature from "../Feature";
import ModalDialog from "../ModalDialog";

function PropertyView(props) {
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState();

  const [isLoadingSaveButton, setIsLoadingSaveButton] = useState(false);
  const [isAllRequiredSelected, setIsAllRequiredSelected] = useState(false);

  const property = appStorage.get('selectedProperty');
  const propertyid = property.sysid;
  const propertyAddress = property.streetaddress + " " +
      (property.municipality || "")  + " " +
      (property.province || "") + " " +
      (property.postalcode || "");

  const areAllRequiredFieldsSelected = () => {

    for (const featureKey in features) {
      if (features[featureKey].required && !features[featureKey].selected){
        return false;
      }
    }
    return (true);
  }

  const doSubmit = () => {
    setIsLoadingSaveButton(true);
    const featuresPayload = { features: {} };
    for (const featureKey in features) {
      const selectedValue = features[featureKey].selected ? features[featureKey].selected : null;
      if (selectedValue) {
        featuresPayload.features[featureKey] = selectedValue;
      }
    }
    featuresPayload["incomingsysid"] = propertyid;
    featuresPayload["inspectionstatus"] = "Completed";
    featuresPayload["inspectorid"] = appStorage.get('userData').username;

    // console.log("featuresPayload: %o", featuresPayload);

    fetch(window.api.inspections, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: new Headers({
        'Authorization': `Bearer ${appStorage.get('userData').authn_token}`,
        'Content-Type': 'application/json'
      }),
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(featuresPayload)
    })
    .then(data => {
      // console.log('Success: %o', data);
      if (data.status === 200){
        setModal({
          caption: <>Inspection saved OK.</>,
          button1: {
            caption: "Back",
            fnOnClick: ()=>{setShowModal(false)}
          },
          button2: {
            caption: "Continue",
            fnOnClick: ()=>{window.location.href= "/MapView";}
          }
        });

      } else {
        setModal({
          caption: <>Error saving inspection!</>,
          // button1: {
          //   caption: "Back",
          //   fnOnClick: ()=>{setShowModal(false)}
          // },
          button2: {
            caption: "Continue",
            fnOnClick: ()=>{setShowModal(false);}
          }
        });
      }
      setShowModal(true);
    })
    .catch((error) => {
      console.error('Error: %o', error);
      setModal({
        caption: <>API connection error!</>,
        button1: {
          caption: "Back",
          fnOnClick: ()=>{setShowModal(false)}
        },
        button2: {
          caption: "Continue",
          fnOnClick: ()=>{setShowModal(false);}
        }
      });
      setShowModal(true);
    })
    .finally( () => {
      setIsLoadingSaveButton(false);
    });
  }

  const [features, setFeatures] = useState({"loading...": { dataType: [], options:[] }});

  useEffect(() => {
    document.title = "Construction Features";
    const fetchFeaturesData = () => {
      fetch(window.api.features,
        {
          headers: new Headers({
            'Authorization': `Bearer ${appStorage.get('userData').authn_token}`
          })
        }
      )
      .then( response => {
        if (response){
          if (response.status===200) return response.json();
          if (response.status===403){
            appStorage.clear();
            window.location.href = '/logout';
          }
        }
        return [];
      })
      .then( data => {
        // console.log("features data: %o", data);

        //data transformation:
        //dataType overrides options when options=="xsd:..."
        for (const [k, feature] of Object.entries(data)){
          // console.log(`k: ${k} v: %o`,feature);

          if (feature.childOf)
            feature.selected = feature.options[0];

          if (!feature.dataType && feature.options){
            if (feature.options.includes("xsd:")){
              data[k].dataType = data[k].options;
              delete data[k].options;
            }
          }

          if (feature.validator){
            // eslint-disable-next-line no-eval
            feature.validator = eval( '(' + feature.validator + ')');
          }


        }
        //console.log("xfrm features: %o", data);
        setFeatures(data);
      })
      .catch( err => {
        console.log("Features API error: %o", err);
      });
    };
    fetchFeaturesData();
  }, []);

  return (
    <>
      {showModal ? ModalDialog(modal) : [] }
      <section className="section property-value">
        <div className="columns is-mobile is-vcentered">
          <div className="column">
            <div
              className='image-thumbnail'
              style={{ backgroundImage: `url(${props.srcPreview})`}}
            />
          </div>
          <div data-cy="property-address" className="column is-size-7">
            {propertyAddress}
          </div>
        </div>
      </section>
      <section data-cy="features" className="section">
        {Object.keys(features).map((k, index) => (
          <Feature key={index}
            feature={
              {
                ...features[k],
                label: k,
              }
            }
            setFnEnabled={ (fnSetFeatureEnabled)=>{
              features[k].setEnabled = fnSetFeatureEnabled;
            }}
            doUpdateValue={ (featureKey, selectedValue) => {
              // console.log("doUpdateValue features: %o", features);
              const childKey=features[featureKey]["parentOf"];
              if (childKey){
                //a parent was clicked
                const featureChild = features[childKey];
                if (["", "None"].includes(selectedValue)){
                  //a parent was de-selected, disable the children
                  // console.log("doUpdateValue childKey: %o", childKey);
                  // console.log("doUpdateValue featureChild: %o", featureChild);
                  featureChild.setEnabled(false);
                }else{
                  //a parent was selected, enable the children
                  featureChild.setEnabled(true);
                }

              }
              features[featureKey].selected = selectedValue;
              setIsAllRequiredSelected(areAllRequiredFieldsSelected());
            }}
          />
        ))}
      </section>
      <section className="section">
        <div className="columns is-mobile">
          <div className="column">
            <button
                className="button is-primary is-pulled-left"
                onClick={ () => props.goPrevious() }
              >
                <span className="icon"><FontAwesomeIcon icon={faChevronLeft} /></span>
                <span>Photos</span>
                <span className="icon"><FontAwesomeIcon icon={faCamera} /></span>
            </button>
            <button
              className={`button is-primary is-pulled-right ${isLoadingSaveButton?'is-loading':''}`}
              onClick={ () => doSubmit() }
              disabled={!isAllRequiredSelected}
            >
              <span>Update</span>
              <span className="icon">
                <FontAwesomeIcon icon={faCloudUploadAlt} />
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default PropertyView;