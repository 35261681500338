import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPen, faEdit } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

function Feature({ feature, doUpdateValue, setFnEnabled}) {
  const [isExpanded, setExpanded] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [isEnabled, setEnabled] = useState(!feature.childOf);
  const [optionsRows, setOptionsRows] = useState([]);
  const [selectedFeature, setSelected] = useState(feature.childOf ? feature.options[0] : "");

  // console.log("Feature: %o", feature);

  const selectOption = (value, toggleExpanded=true) => {
    // console.log("selectOption feature: %o", feature);
    if (selectedFeature === value){
        setSelected("");
        doUpdateValue(feature.label, "");
    } else {
        setSelected(value);
        doUpdateValue(feature.label, value);
    }
    if (toggleExpanded) setExpanded(!isExpanded);
  };

  useEffect(() => {
    // console.log("useEffect() feature: %o", feature);
    if (setFnEnabled){
      setFnEnabled(setEnabled);
    }

    if (feature.dataType && feature.dataType.length > 0){
      //nop
    } else {
      const MAX_PER_COLUMN = 3;
      const rowsCount = Math.ceil(feature.options.length - (feature.childOf?1:0) / MAX_PER_COLUMN);
      const _optionsRows = [];
      for (let i = 0; i < rowsCount; i++) {
        _optionsRows[i] = feature.options.slice(i * MAX_PER_COLUMN + (feature.childOf?1:0), (i + 1) * MAX_PER_COLUMN + (feature.childOf?1:0));
      }
      setOptionsRows(_optionsRows);
    }
  }, [feature, setFnEnabled]);

  let expandedPanel;
  if (isExpanded){
    if (!feature.dataType){
      expandedPanel = optionsRows.map((optionRow, rowsIndex) => (
        <div key={rowsIndex} className="columns is-mobile pv-options is-vcentered">
          {optionRow.map((option, rowIndex) => (
            <div key={rowIndex} className="column is-4">
              <button
                className={`button is-small is-fullwidth feature-select ${
                  option === selectedFeature ? "has-text-white has-background-primary" : "is-light"
                }`}
                onClick={() => selectOption(option)}
              >
                {option}
              </button>
            </div>
          ))}
        </div>
      ))
    } else {
      // console.log("FREEFORM Feature: %o", feature.options.dataType);
      let inputType="text";
      switch(feature.dataType){
        case "xsd:integer":
          // console.log("setting inputType to number");
          inputType="number";
          break;
        default: inputType="text";
      }
      expandedPanel = <div className="columns is-mobile pv-options is-centered">
        <div className="column is-8">
          <input
            placeholder={feature.label}
            className="pv-option-freetext"
            autoFocus={true}
            onBlur={()=>{
              if (feature.validator){
                const {err, msg} = feature.validator(selectedFeature);
                if (err){
                  setValidationMessage(msg);
                  return true;
                } else {
                  setValidationMessage("");
                }
              }
              setTimeout( ()=>{setExpanded(false)},250);
            }}
            onKeyDown={ (e)=> {
              if(e.key=== "Enter") {
                if (feature.validator){
                  const {err, msg} = feature.validator(selectedFeature);
                  if (err){
                    setValidationMessage(msg);
                    return true;
                  } else {
                    setValidationMessage("");
                  }
                }
                setExpanded(false);
              }
            }}
            type={inputType}
            value={selectedFeature}
            onChange={ (e)=>{
              setSelected(e.target.value);
              if (feature.validator){
                const {err, msg} = feature.validator(e.target.value);
                if (err){
                  setValidationMessage(msg);
                  return true;
                } else {
                  setValidationMessage("");
                }
              }
              doUpdateValue(feature.label, e.target.value);

            }}
          />
        </div>
      </div>
      }
  }

  return (
    <div className={`feature-container`}>
      <div
        data-cy={`feature-clickable-${feature.label}`}
        className={`columns is-mobile is-clickable pv-actionable-row is-gapless is-multiline p-2 mb-2 ${isEnabled?"showme":"hideme"}`}
        onClick={() => setExpanded(!isExpanded)}
      >
        <div data-cy={`feature-label-${feature.label}`} className="column is-5 is-size-6 has-text-left feature-label">
          {/* {isEnabled?"Y ":"N "} */}
          {feature.label}

        </div>
        <div
          data-cy={`feature-value-${feature.label}`}
          className={`column is-5 is-size-6 has-text-right feature-selected ${isEnabled?"showme":"hideme"}`}
        >
          {selectedFeature}
        </div>
        <div className={`column is-2 has-text-right ${isEnabled?"showme":"hideme"}`}>
          <span className="icon">
            <FontAwesomeIcon icon={
              (
                [undefined, ""].includes(selectedFeature) ||
                ![undefined, ""].includes(validationMessage)
              )?
                (feature.required ? faPen : faEdit)
            : faCheckCircle} />
          </span>
        </div>
      </div>
      <div className={`feature-validation ${validationMessage?"showvalidation":"hidevalidation"}`}>{validationMessage}</div>
      <div data-cy={`feature-options-${feature.label}`} className="feature-options">
        {expandedPanel}
      </div>
    </div>
  );
}

export default Feature;