import React from "react";
import "./styles.scss";
import pvoptalogo from "../assets/pv-opta-logo.png";
import locationReviewImage from "../assets/undraw_location_review_dmxd.svg";
import { Link } from "react-router-dom";
import { appStorage } from "../../util";


function Logout() {
  appStorage.clear();

  return (
    <section className="section">
      <div className="columns is-centered">
          <img data-cy="login-logo" className="is-pulled-right" src={pvoptalogo} alt="OPTA" />
      </div>
      <div className="columns is-centered acell">
        You have been logged out.
      </div>
      <div className="columns is-centered acell">
        <Link to="/login" className="button is-primary">
          <strong>Log In</strong>
        </Link>
      </div>
      <footer className="footer">
        <img className="is-pulled-right" src={locationReviewImage} alt="location review" width="170" height="153" />
      </footer>
    </section>
  );

}

export default Logout;
