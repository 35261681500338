import React from "react";
import "./styles.scss";

function ModalDialog(settings){
  const btn1 = settings.button1 ? (
    <button
      className="button is-primary is-pulled-left"
      onClick={ () => settings.button1.fnOnClick() }
    >
      <span>{settings.button1.caption}</span>
    </button>
  ) : [];

  const btn2 = settings.button2 ? (
    <button
      className="button is-primary is-pulled-right"
      onClick={ () => settings.button2.fnOnClick() }
    >
      <span>{settings.button2.caption}</span>
    </button>
  ) : [];

  return (
  <div className="modal-dialog-container">
    <div className="modal-dialog">
      <div className="caption">
        {settings.caption}
      </div>

      <div className="columns the-buttons">
        <div className="column">
          {btn1}
          {btn2}
        </div>
      </div>
    </div>
  </div>
  );
}

export default ModalDialog;
